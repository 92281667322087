<template>
  <v-dialog
      v-model="dialog"
      :width="surveyCreate ? `90%` : `auto`"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      dense
      class="dialog-style"
  >
    <v-card style="overflow: auto;">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{ $t("mentalHealthTemplate") }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="closeTemplate"
        >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-start pa-6">
          <span style="font-size: 16px">{{ $t("name") }}</span>
        </v-row>
        <v-row class="mx-2">
          <v-col class="my-5">
            <v-row>
              <v-text-field
                  :label="this.$t('name')"
                  dense
                  v-model="survey.templateName"
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  style="border-radius: 12px !important; height: 40px"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-autocomplete
                  :label="this.$t('type')"
                  :items="mentalHealthCategory"
                  v-model="survey.mentalHealthCategoryId"
                  dense
                  :item-text="this.$i18n.locale === 'en' ? `shortDescription` : `shortDescriptionEsp`"
                  item-value="lowValue"
                  background-color="#E8E8E8"
                  rounded
                  class="my-1"
                  outlined
                  style="border-radius: 12px !important; height: 40px"
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-textarea
                  :label="this.$t('description')"
                  dense
                  v-model="survey.templateDescription"
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  no-resize
                  style="border-radius: 12px !important; height: 40px"
              ></v-textarea>
            </v-row>
            <v-row>
<!--              save button-->
              <v-btn
                  v-if="!surveyCreate"
                  class="table-button mx-2"
                  @click="saveSurvey"
              >
                <span>{{ $t("save") }}</span>
              </v-btn>
            </v-row>
          </v-col>
          <v-col class="col-9" v-if="surveyCreate || update" style="height: 900px;">
            <v-form>
              <!--            <draggable v-model="questions">-->
              <v-card class="my-2 secondary elevation-0" v-for="(question, index) in questions" :key="index">
                <v-row class="my-2">
                  <v-col cols="1 ml-3">
                    <v-avatar
                        color="primary"
                        size="30"
                    ><span style="color: white">{{index + 1}}</span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                        hide-details dense
                        style="font-weight: bold; font-size: 20px"
                        v-model="question.name"
                        @keyup="submit"
                    ></v-text-field>
                  </v-col>
                  <v-icon @click="deleteQuestion(question, index)" color="primary">mdi-close-circle</v-icon>
                </v-row>
                <v-radio-group
                    row
                    v-model="question.type"
                    class="ml-10"
                >
                  <v-radio
                      v-for="(verifiers, index) in surveyAnswerType" :key="index"
                      :label="$i18n.locale === 'en' ? verifiers.shortDescription : verifiers.shortDescriptionEsp"
                      :value="verifiers.lowValue"
                  ></v-radio>
                  <span v-if="hideButton[index] === true && !temp">
                    <v-btn
                        color="primary"
                        dark
                        :disabled="temp"
                        class="table-create-button mx-2 my-1 d-flex justify-start"
                        @click="createQuestion(index, question)"
                    >
                      {{ $t("create-question") }}
                    </v-btn>
                  </span>
                  <span v-if="hideButton[index] === false">
                    <v-btn
                        color="primary"
                        dark
                        class="table-create-button mx-2 my-1 d-flex justify-start"
                        @click="updateQuestion(question, index)"
                    >
                      {{ $t("update-question") }}
                    </v-btn>
                  </span>
                </v-radio-group>
                <div v-if="question.type === 1 && hideAnswers[index] === true">
                  <div class="ma-2">
                    <v-row>
                      <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="questions[index].options"
                            sort-by="calories"
                            :no-data-text="$t('no-option-entered')"
                            :items-per-page="-1"
                            hide-default-footer
                            hide-default-header
                            dense
                            class="elevation-0"
                            :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                        >
                          <template v-slot:item.checkbox="{ item }">
                            <v-checkbox hide-details disabled v-model="selected" :value="item.id" class="ma-0"></v-checkbox>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item, index, question.type)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item, index)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                        <v-row>
                          <v-col>
                            <v-btn
                                color="primary"
                                dark
                                class="table-create-button mx-2 my-1 d-flex justify-start"
                                @click="addNewDialog(index, true)"
                            >
                              {{ $t("add-new-answer") }}
                            </v-btn>
                          </v-col>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-dialog
                            v-model="dialog2"
                            max-width="500px"
                        >
                          <v-card class="elevation-0" dense>
                            <v-card-title>
                              <span class="text-h5">{{ title === 1 ? $t("add-answer") : $t("edit-answer") }}</span>
                              <v-spacer></v-spacer>
                              <v-card-actions>
                                <v-icon
                                    color="primary"
                                    text
                                    @click="closeDialog"
                                >
                                  mdi-close
                                </v-icon>
                              </v-card-actions>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                  >
                                    <v-text-field
                                        v-model="newAnswer[index]"
                                        :label="$t('answer')"
                                        dense
                                        outlined
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-row dense>
                                <v-col dense class="ml-5">
                                  <v-row>
                                    <v-checkbox
                                        v-model="checkbox"
                                    ></v-checkbox>
                                    <span class="mt-5">{{$t('trigger-notification')}}</span>
                                  </v-row>
                                </v-col>
                                <v-col dense>
                                  <v-btn
                                      class="table-create-button mx-2 my-1"
                                      text
                                      :disabled="disableAddAnswer"
                                      dense
                                      @click="save(index, newAnswer[index], questions)"
                                  >
                                    {{ title === 1 ? $t("add") : $t("edit") }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-if="question.type === 2 && hideAnswers[index] === true">
                  <div class="ma-2">
                    <v-row>
                      <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="questions[index].options"
                            sort-by="calories"
                            :no-data-text="$t('no-option-entered')"
                            :items-per-page="-1"
                            dense
                            hide-default-footer
                            hide-default-header
                            class="elevation-0"
                            :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                        >
                          <template v-slot:item.checkbox="{ item }">
                            <v-checkbox hide-details disabled v-model="selected" :value="item.id" class="ma-0"></v-checkbox>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item, index, question.type)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item, index)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                        <v-row>
                          <v-col>
                            <v-btn
                                color="primary"
                                dark
                                class="table-create-button mx-2 my-1 d-flex justify-start"
                                @click="addNewDialog(index, false)"
                            >
                              {{ $t("add-new-answer") }}
                            </v-btn>
                          </v-col>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-col>
                      <v-dialog  v-model="dialog1" max-width="500px">
                        <v-card class="elevation-0" dense>
                          <v-card-title>
                            <span class="text-h5">{{ title === 1 ? $t("add-answer") : $t("edit-answer") }}</span>
                            <v-spacer></v-spacer>
                            <v-card-actions>
                              <v-icon
                                  color="primary"
                                  text
                                  @click="closeDialog"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-actions>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                >
                                  <v-text-field
                                      v-model="newAnswer[index]"
                                      :label="$t('answer')"
                                      dense
                                      outlined
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-row dense>
                              <v-col dense>
                                <v-row>
                                    <v-checkbox
                                        class="ml-5"
                                        dense
                                        v-model="checkbox"
                                    ></v-checkbox>
                                  <span class="mt-2">{{$t('trigger-notification')}}</span>
                                </v-row>
                              </v-col>
                              <v-col dense>
                                <v-btn
                                    class="table-create-button mx-2 my-1"
                                    text
                                    dense
                                    :disabled="disableAddAnswer"
                                    @click="save(index, newAnswer[index], questions)"
                                >
                                  {{ title === 1 ? $t("add") : $t("edit") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </div>
                </div>
                <br />
              </v-card>
              <span v-if="disableAddQuestion === true">
                <v-btn class="elevation-0" style="background-color: white" @click="addQuestion()">
                <v-avatar
                    color="primary"
                    size="30"
                ><v-icon style="color: white">mdi-plus</v-icon>
                </v-avatar>
              </v-btn>
              </span>
              <v-row class="ma-3">
                <v-spacer></v-spacer>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import draggable from 'vuedraggable';
import { mapState } from 'vuex';
export default {
	props: ['dialog', 'update', 'surveyAnswerType', 'mentalHealthCategory'],
	components: {
		// draggable,
	},
	data () {
		return {
			dialog1: false,
			answerId: {},
			hideButton: [true],
			checkbox: false,
			notifyAnswer: false,
			questionDelete: true,
			disableAddAnswer: true,
			indexQuestion: null,
			disableAddQuestion: false,
			dialogDelete: false,
			newIndex: null,
			surveyCreate: false,
			selected: [],
			hideAnswers: [],
			temp: true,
			surveyId: null,
			survey: {
				templateName: '',
				mentalHealthCategoryId: '',
				templateDescription: '',
			},
			dialog2: false,
			newAnswer: [],
			itemForEdit: {},
			title: 1,
			editedItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			defaultItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			questions: [
				{
					name: '',
					type: 3,
					options: [],
				},
			],
		};
	},
	computed: {
		...mapState({
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.survey,
		}),
		headers () {
			return [
				{ text: 'Trigger alert on this', value: 'checkbox', sortable: true, width: '30%' },
				{ text: 'Name', value: 'possibleAnswer', sortable: true, width: '50%' },
				{ text: 'Actions', value: 'actions', sortable: true, width: '20%' },
			];
		},
	},

	watch: {
		newAnswer (val) {
			if (val[this.newIndex] === '') {
				this.disableAddAnswer = true;
			} else {
				this.disableAddAnswer = false;
			}
		},
		checkbox (val) {
			this.notifyAnswer = val;
		},
		surveyById (val) {
			if (this.update) {
				this.surveyCreate = true;
				this.disableAddQuestion = true;
				for (let i = 0; i < val.questionsListWithAnswers.length; i++) {
					this.hideButton[i] = false;
				}
				this.disableAddQuestion = true;
				this.survey = {
					templateName: val.templateName,
					mentalHealthCategoryId: val.mentalHealthCategoryId,
					templateDescription: val.templateDescription,
				};
				this.hideAnswers = [];
				this.questions.push(
					{
						name: '',
						type: '',
						options: [],
						id: null,
					},
				);
				for (let i = 0; i < val.questionsListWithAnswers.length; i++) {
					this.hideAnswers.push(true);
				}
				this.getAllSelectedAnswers();
				this.surveyId = val.id;
				var option = [];
				this.questions = [];
				val.questionsListWithAnswers.forEach(item => {
					item.offeredAnswersList.forEach(element => {
						option.push(element);
					});
					var tempQuestion = {
						name: item.question,
						type: item.answerTypeId,
						options: option,
						id: item.id,
					};
					option = [];
					this.questions.push(tempQuestion);
				});
			} else if (!this.update && this.title !== 1) {
				this.hideAnswers.push(false);
			}
		},
		question (val) {
		},
		dialog (val) {
			val || this.close();
		},
		dialogDelete (val) {
			val || this.closeDelete();
		},
	},
	methods: {
		addNewDialog (index, temp) {
			if (temp) {
				this.dialog2 = true;
			} else {
				this.dialog1 = true;
			}
			this.newIndex = index;
		},
		submit () {
			if (this.questions[0].name !== '') {
				this.temp = false;
			} else {
				this.temp = true;
			}
		},
		closeTemplate () {
			this.temp = true;
			this.survey = {
				templateName: '',
				mentalHealthCategoryId: '',
				templateDescription: '',
			};
			this.$emit('close');
			this.$emit('refresh-surveys');
			this.surveyCreate = false;
		},
		createQuestion (index, question) {
			this.questionDelete = false;
			if (question.type === 3) {
				this.disableAddQuestion = true;
			}
			this.indexQuestion = index;
			this.hideButton[index] = false;
			var object = {
				surveyTemplateId: this.surveyId,
				question: this.questions[this.questions.length - 1].name,
				answerTypeId: this.questions[this.questions.length - 1].type,
				orderNumber: this.questions.length,
				isMandatory: true,
			};
			this.$store.dispatch('mentalhealth/createQuestion', object).then(res => {
				this.surveyCreate = true;
				this.questions[this.questions.length - 1].id = res.id;
			});
			this.hideAnswers.push(true);
		},
		deleteQuestion (item, index) {
			if (item.id !== null) {
				this.questionDelete = false;
				this.hideButton.splice(index, 1);
				this.indexQuestion = index;
				this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
					if (this.update) {
						this.$emit('update-survey');
					} else {
						this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
					}
				});
			} else {
				this.questionDelete = true;
				this.disableAddQuestion = true;
				this.hideButton.splice(index, 1);
				this.indexQuestion = index;
				this.questions.splice(index, 1);
			}
		},
		getAllSelectedAnswers () {
			this.selected = [];
			this.surveyById.questionsListWithAnswers.forEach(item => {
				item.offeredAnswersList.forEach(element => {
					if (element.triggerNotification) {
						this.selected.push(
							element.id,
						);
					}
				});
			});
		},
		updateQuestion (question, index) {
			const object = {
				question: question.name,
				answerTypeId: question.type,
				orderNumber: index + 1,
				isMandatory: true,
				id: question.id,
			};
			this.$store.dispatch('mentalhealth/updateQuestion', object);
		},
		closeDialog () {
			this.dialog2 = false;
			this.dialog1 = false;
		},

		editItem (item, index, type) {
			this.disableAddAnswer = true;
			this.newIndex = index;
			if (type === 1) {
				this.dialog2 = true;
			} else {
				this.dialog1 = true;
			}
			this.title = 2;
			this.itemForEdit = {
				item: item,
				index: index,
			};
		},
		async saveSurvey () {
			this.disableAddQuestion = false;
			this.hideButton = [true];
			this.hideAnswers = [];
			this.questions = [
				{
					name: '',
					type: 3,
					options: [],
					id: null,
				},
			];
			await this.$store.dispatch('mentalhealth/createSurvey', this.survey).then(res => {
				this.surveyId = res.id;
			});
			this.surveyCreate = true;
		},
		deleteItem (item, index) {
			this.questions[index].options = this.questions[index].options.filter(element => element.possibleAnswer !== item.possibleAnswer);
			this.$store.dispatch('mentalhealth/deleteAnswer', item.id);
		},

		close () {
			this.dialog1 = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
		},

		closeDelete () {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
		},

		save (index, answer, question) {
			this.disableAddQuestion = true;
			if (this.title === 1) {
				question[index].options.push({ name: this.newAnswer[index], id: question[index].options.length + 1 });
				this.newAnswer = [];
				var createAnswer = {
					stQuestionId: question[index].id,
					possibleAnswer: question[index].options[question[index].options.length - 1].name,
					orderNumber: question[index].options[question[index].options.length - 1].id,
					triggerNotification: this.notifyAnswer,
				};
				this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then(res => {
					if (res.statusCode === 201) {
						this.dialog2 = false;
						this.dialog1 = false;
					}
					question[index].options.pop();
					question[index].options.push(res.data);
					if (res.data.triggerNotification) {
						this.selected.push(
							res.data.id,
						);
					}
					this.surveyCreate = true;
				});
				this.checkbox = false;
				this.newAnswer[this.indexQuestion] = '';
			} else {
				this.itemForEdit.item.possibleAnswer = this.newAnswer[this.itemForEdit.index];
				const answerId = question[this.newIndex].options.find(element => element.id === this.itemForEdit.item.id);
				const object = {
					stQuestionId: question[this.newIndex].id,
					possibleAnswer: answer,
					orderNumber: this.itemForEdit.item.id,
					id: answerId.id,
					triggerNotification: this.notifyAnswer,
				};
				this.$store.dispatch('mentalhealth/updateAnswer', object).then(res => {
					if (res.statusCode === 201) {
						this.dialog2 = false;
						this.dialog1 = false;
					}
					this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId).then(() => {
					});
				});
				this.newAnswer = [];
				this.title = 1;
				this.checkbox = false;
				this.newAnswer[this.indexQuestion] = '';
			}
		},
		async addQuestion () {
			this.temp = true;
			if (!this.questionDelete) {
				this.hideButton[this.hideButton.length] = true;
			} else if (this.questionDelete) {
				this.hideButton[this.hideButton.length] = true;
			}
			this.disableAddQuestion = false;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
	},
	mounted () {
		this.submit();
	},
};
</script>
