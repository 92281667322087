import { render, staticRenderFns } from "./MentalHealth.vue?vue&type=template&id=06bf3bcf&scoped=true&xmlns%3Aadd-medical-health-tamplate=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./MentalHealth.vue?vue&type=script&lang=js"
export * from "./MentalHealth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bf3bcf",
  null
  
)

export default component.exports