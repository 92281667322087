<template xmlns:add-medical-health-tamplate="http://www.w3.org/1999/html">
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
              ><h5 class="d-flex align-left">{{ $t("mentalHealthTemplate") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search-medical-template-medical-health')"
                    single-line
                    hide-details
                    filled
                    dense
                    background-color="#E8E8E8"
                    rounded
                    class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers-length="headers.length"
              :search="search"
              :items="tamplates"
              :items-per-page="-1"
              fixed-header
              dense
              style="cursor: pointer"
              :height="testHeight"
              :group-by="this.$i18n.locale === 'en' ? 'mentalHealthCategoryTitle' : 'mentalHealthCategoryTitleEsp'"
              :single-expand="true"
              :expanded.sync="expanded"
              ref="table"
              :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
<!--            <template v-slot:[`group.header`]="{ group, headers, items, toggle }">-->
<!--              <td @click="toggle" style="vertical-align: !important; text-align: left !important;" :colspan="headers.length + 1">-->
<!--                <span style="font-weight: bold">{{group}}</span>-->
<!--                <v-chip pill x-small color="primary" class="ma-2 text&#45;&#45;black">{{ items.length }}</v-chip>-->
<!--                <span style="float: right;" class="mt-2">-->
<!--									<v-icon color="primary" v-if="isOpen" >mdi-arrow-down-drop-circle</v-icon>-->
<!--									<v-icon color="primary" v-else >mdi-arrow-up-drop-circle</v-icon>-->
<!--								</span>-->
<!--              </td>-->
<!--            </template>-->
            <template v-slot:[`group.header`]="{ group,headers,toggle,isOpen,items, }">
              <td @click="toggle" style="vertical-align: left !important; text-align: left !important;" :colspan="headers.length">
                  <span style="float: right;">
                    <v-btn  small icon :ref="group" :data-open="isOpen">
                      <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                      <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </span>
                <span style="font-weight: bold">
                    <span style="font-weight: bold">{{group}}</span>
                    <v-chip pill x-small color="primary" class="ma-2 text--black">{{ items.length }}</v-chip>
                </span>
              </td>
              <v-spacer></v-spacer>
            </template>
            <template v-slot:item="{item}">
              <tr  @click="editSurvey(item)">
                <td  class="table-cols">{{ item.templateName }}</td>
                <td  class="table-cols">{{ item.templateDescription }}</td>
                <td  class="table-cols">{{ item.createdByFullname }}</td>
                <td  class="table-cols d-flex justify-end">
                  <v-icon  @click="editSurvey(item)">mdi-pencil</v-icon>
                  <v-icon  @click.stop="deleteSurvey(item)">mdi-delete</v-icon></td>
              </tr>
            </template>
            <template v-slot:[`footer.prepend`]>
              <v-btn
                  v-if="roleName === 'DOCTOR' || roleName === 'NURSE'"
                  class="table-create-button"
                  label="AddDialog"
                  @click="createSurvey"
              >
                <img class="pt-1" height="20px" width="20px" :src="medical_health_white" style="margin-right:2%; margin-bottom: 5%;"/>
                <span style="text-transform: none;">{{ $t("addMentalHealtTemplate") }}</span>
              </v-btn>
            </template>
            <!-- <template v-if="roleName === 'HOSPITAL_ADMIN'" v-slot:[`footer.prepend`]="{}">
              <v-btn
                class="table-create-button"
                label="AddDialog"
                @click="manageMedicalTeamDialog = true"
              >
                <span>{{ $t("addNewMedicalTeam") }}</span>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card>
<!--        add medical health template-->
        <add-medical-health-tamplate @close="manageMentalHealthDialog=false" @update-survey="editSurvey(itemForEdit)" @refresh-surveys="loadData" :surveyAnswerType="surveyAnswerType" :mentalHealthCategory="mentalHealthCategory" :update="update" :dialog="manageMentalHealthDialog"></add-medical-health-tamplate>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { pencilImg, mentalHealthWhite } from '@/assets';
import AddMedicalHealthTamplate from '@/views/dialogs/AddMedicalHealthTamplate';
export default {
	components: {
		AddMedicalHealthTamplate,
	},
	data () {
		return {
			expanded: [],
			mentalHealthCategory: [],
			surveyAnswerType: [],
			itemForEdit: {},
			search: '',
			pencilImg: pencilImg,
			update: false,
			patientId: null,
			manageMentalHealthDialog: false,
			medical_health_white: mentalHealthWhite,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			surveyFromRepo: (state) => state.mentalhealth.surveys,
			verifires: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		tamplates () {
			if (this.surveyFromRepo) {
				return this.surveyFromRepo;
			} else {
				return [];
			}
		},
		headers () {
			return [
				{
					text: this.$t('name'),
					value: 'templateName',
					sortable: true,
					width: '15%',
				},
				{
					text: this.$t('description'),
					value: 'templateDescription',
					sortable: true,
					width: '60%',
				},
				{
					text: this.$t('created-by'),
					value: 'createdByFullname',
					sortable: true,
					width: '16%',
				},
				{
					align: 'start',
					sortable: false,
				},
				// <!-- JUST FOR SAKE OF SEARCHING THE HEADER -->
				// {
				// 	text: '',
				// 	sortable: false,
				// 	value: 'patientFullname',
				// 	class: 'text-end black_inner--text !important',
				// },
				// <!-- END OF JUST FOR SAKE OF SEARCHING THE HEADER -->
			];
		},
	},
	watch: {
		async manageMentalHealthDialog () {
			const header = { domainsList: 'MENTAL_HEALTH_CATEGORY' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header).then(() => {
				this.mentalHealthCategory = this.verifires;
			});
			const header2 = { domainsList: 'SURVEY_ANSWER_TYPE' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header2).then(() => {
				// remove number rsnge
				this.surveyAnswerType = [];
				this.verifires.forEach(item => {
					if (item.lowValue !== 4) { this.surveyAnswerType.push(item); }
				});
				this.surveyCreate = true;
			});
		},
	},
	methods: {
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		createSurvey () {
			this.manageMentalHealthDialog = true;
			this.update = false;
		},
		deleteSurvey (item) {
			this.$store.dispatch('mentalhealth/deleteSurveysById', item.id).then(() => {
				this.loadData();
			});
		},
		editSurvey (item) {
			this.itemForEdit = item;
			this.manageMentalHealthDialog = true;
			this.update = true;
			this.$store.dispatch('mentalhealth/getSurveysById', item.id);
		},
		loadData () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospitalId).then(() => {
				this.closeAll();
			});
		},
	},
	mounted () {
		this.loadData();
	},
};
</script>
<style scoped>
</style>
